import PaymentAccount, { PaymentAccountJSON } from '../model/PaymentAccount'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const putPaymentAccount = async (requestParams: PutPaymentAccountRequestParams): Promise<PaymentAccount> => {
  // Build request
  const url = urlForEndpoint(`payment-accounts/${requestParams.paymentAccountID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    ...requestParams,
    paymentAccountID: undefined
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { paymentAccount } = await parseResponse(response)
    return new PaymentAccount(paymentAccount as PaymentAccountJSON)
  } catch (err) {
    throw new LoopError(err, requestParams)
  }
}

export const putObject = async <Type>(requestParams: PutPaymentAccountRequestParams): Promise<PaymentAccount> => {
  // Build request
  const url = urlForEndpoint(`payment-accounts/${requestParams.paymentAccountID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    ...requestParams,
    paymentAccountID: undefined
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { paymentAccount } = await parseResponse(response)
    return new PaymentAccount(paymentAccount as PaymentAccountJSON)
  } catch (err) {
    throw new LoopError(err, requestParams)
  }
}


export interface PaymentAccountByIDRequestParams {
  paymentAccountID: number
}

export interface PaymentAccountByIDErrorResponse {
  error: Error
  requestParams: PaymentAccountByIDRequestParams
}

export interface PostPaymentAccountRequestParams {
  name: string
  paymentMethod: string
  accountID: string
  branchVisibility: number[]
}

export interface PutPaymentAccountRequestParams extends PostPaymentAccountRequestParams {
  paymentAccountID: number
}

export interface PutPaymentAccountErrorResponse {
  error: Error
  requestParams: PutPaymentAccountRequestParams
}